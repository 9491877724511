import React from "react";
import "./portfolio-pages.css";
import {
  nightStreetImages,
  streetImages,
} from "../imageUrlLists/streetImagesList";

function StreetPortfolio() {
  return (
    <div className="container-custom">
      <hr style={{ color: "white", marginTop: 0 }} />
      <h1
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          backgroundColor: "rgb(24 61 61 / 46%)",
        }}
      >
        Street Photos
      </h1>
      <hr style={{ color: "white" }} />
      <div className="img-container">
        {streetImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{ width: image.width ?? "", height: image.height ?? "" }}
            />
          );
        })}
      </div>
      <hr style={{ color: "white" }} />
      <div>
        <h1
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            backgroundColor: "rgb(24 61 61 / 46%)",
          }}
        >
          Street & urban photos at night
        </h1>
      </div>
      <hr style={{ color: "white" }} />
      <div className="img-container">
        {nightStreetImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default StreetPortfolio;
