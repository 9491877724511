import londonImage1 from "../assets/london_pictures/London 1.jpg";
import londonImage2 from "../assets/london_pictures/London 4.jpg";
import londonImage3 from "../assets/london_pictures/London 2.jpg";
import londonImage4 from "../assets/london_pictures/London 3.jpg";
import londonImage5 from "../assets/london_pictures/London 5.jpg";
import londonImage6 from "../assets/london_pictures/London 6.jpg";
import londonImage7 from "../assets/london_pictures/London 7.jpg";
import londonImage8 from "../assets/london_pictures/London 8.jpg";
import londonImage9 from "../assets/london_pictures/London 9.jpg";
import londonImage10 from "../assets/london_pictures/London 11.jpg";
import londonImage11 from "../assets/london_pictures/London 16.jpg";
import londonImage12 from "../assets/london_pictures/London 17.jpg";
import londonImage13 from "../assets/london_pictures/London 18.jpg";
import londonImage14 from "../assets/london_pictures/London 19.jpg";
import londonImage15 from "../assets/london_pictures/Collage B&W London.jpg";
import londonImage16 from "../assets/london_pictures/London 13.jpg";
import londonImage17 from "../assets/london_pictures/London 14.jpg";
import londonImage18 from "../assets/london_pictures/London 15.jpg";
import londonImage19 from "../assets/london_pictures/London 12.jpg";

export const londonImages = [
  {
    src: londonImage1,
  },
  {
    src: londonImage2,
  },
  {
    src: londonImage3,
  },
  {
    src: londonImage4,
  },
  {
    src: londonImage5,
  },
  {
    src: londonImage6,
    width: "680px",
    height: "450px",
  },
  {
    src: londonImage7,
  },
  {
    src: londonImage8,
    width: "600px",
    height: "450px",
  },
  {
    src: londonImage9,
    width: "600px",
    height: "450px",
  },
  {
    src: londonImage10,
  },
  {
    src: londonImage11,
  },
  {
    src: londonImage12,
  },
  {
    src: londonImage13,
  },
  {
    src: londonImage14,
  },
  {
    src: londonImage15,
    width: "500px",
    height: "450px",
  },
  {
    src: londonImage16,
  },
  {
    src: londonImage17,
    width: "720px",
    height: "450px",
  },
  {
    src: londonImage18,
    width: "720px",
    height: "450px",
  },
  {
    src: londonImage19,
    width: "650px",
    height: "450px",
  },
];
