import northsea1 from "../assets/northsea_pictures/Wattenmeer-14-min.jpg";
import northsea2 from "../assets/northsea_pictures/Wattenmeer-21-min.jpg";
import northsea3 from "../assets/northsea_pictures/Wattenmeer-4-min.jpg";
import northsea4 from "../assets/northsea_pictures/Durum-17-min.jpg";
import northsea5 from "../assets/northsea_pictures/Durum-22-min.jpg";
import northsea6 from "../assets/northsea_pictures/Wremen-3-min.jpg";
import northsea7 from "../assets/northsea_pictures/Wattenmeer-15-min.jpg";
import northsea8 from "../assets/northsea_pictures/Wremen-12-min.jpg";

export const northseaImages = [
  {
    src: northsea1,
  },

  {
    src: northsea3,
    width: "550px",
    height: "450px",
  },
  {
    src: northsea2,
  },
  {
    src: northsea4,
  },
  {
    src: northsea6,
  },
  {
    src: northsea5,
  },
  {
    src: northsea8,
  },
  {
    src: northsea7,
    width: "650px",
    height: "450px",
  },
];
