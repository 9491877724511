import React from "react";
import "./portfolio-pages.css";
import { londonImages } from "../imageUrlLists/londonImagesList";

function LondonPortfolio() {
  return (
    <div className="container-custom">
      <hr style={{ color: "white", marginTop: 0 }} />
      <h1
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          backgroundColor: "rgb(24 61 61 / 46%)",
        }}
      >
        London
      </h1>
      <hr style={{ color: "white" }} />
      <div className="img-container">
        {londonImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default LondonPortfolio;
