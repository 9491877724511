import eibsee2 from "../assets/landscapes/Eibsee-2.jpg";
import eibsee3 from "../assets/landscapes/Eibsee-3.jpg";
import eibsee7 from "../assets/landscapes/Eibsee-7.jpg";
import eibsee11 from "../assets/landscapes/Eibsee-11.jpg";
import eibsee13 from "../assets/landscapes/Eibsee-13.jpg";
import eibsee14 from "../assets/landscapes/Eibsee-14.jpg";
import eibsee15 from "../assets/landscapes/Eibsee-15.jpg";

export const landscapeImages = [
  {
    src: eibsee2,
  },
  {
    src: eibsee3,
  },
  {
    src: eibsee7,
  },
  {
    src: eibsee11,
  },
  {
    src: eibsee13,
  },
  {
    src: eibsee14,
  },
  {
    src: eibsee15,
    width: "700px",
    height: "450px",
  },
];
