import React from "react";
import "./portfolio-pages.css";
import LondonPortfolio from "./LondonPortfolio";
import NeuschwansteinPortfolio from "./NeuschwansteinPortfolio";
import NorthseaPortfolio from "./NorthseaPortfolio";

function TravelsPortfolio() {
  return (
    <div className="container-custom">
      <LondonPortfolio />
      <NeuschwansteinPortfolio />
      <NorthseaPortfolio />
    </div>
  );
}

export default TravelsPortfolio;
