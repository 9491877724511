import macroInsect1 from "../assets/wild_life_pictures/Grass Hopper1-min.png";
import macroInsect2 from "../assets/wild_life_pictures/Grass Hopper2-min.png";
import macroInsect3 from "../assets/wild_life_pictures/Spider 1-min.jpg";
import macroInsect4 from "../assets/wild_life_pictures/Schmetterling 1.jpg";
import birds1 from "../assets/wild_life_pictures/Wildpark Poing-10-min.jpg";
import birds2 from "../assets/wild_life_pictures/Wildpark Poing-12-min.jpg";
import birds3 from "../assets/wild_life_pictures/Wildpark Poing-17-min.jpg";
import birds4 from "../assets/wild_life_pictures/Wildpark Poing-24-min.jpg";
import birds5 from "../assets/wild_life_pictures/Wildpark Poing-25-min.jpg";
import birds6 from "../assets/wild_life_pictures/Wildpark Poing-7-min.jpg";

export const macroInsectImages = [
  {
    src: macroInsect1,
    width: "430px",
    height: "450px",
  },
  {
    src: macroInsect2,
    width: "430px",
    height: "450px",
  },
  {
    src: macroInsect3,
    width: "450px",
    height: "450px",
  },
  {
    src: macroInsect4,
    width: "780px",
    height: "650px",
  },
];

export const birdsImages = [
  {
    src: birds1,
  },
  {
    src: birds2,
  },
  {
    src: birds3,
  },

  {
    src: birds4,
  },

  {
    src: birds5,
  },

  {
    src: birds6,
  },
];
