import React from "react";
import "./portfolio-pages.css";
import {
  forestImages,
  natureImages,
} from "../imageUrlLists/natureForestImagesList";

function NatureForestPortfolio() {
  return (
    <div className="container-custom">
      <hr style={{ color: "white", margin: 0 }} />
      <h1
        style={{
          paddingTop: "30px",
          paddingBottom: "20px",
        }}
      >
        Flowers and Forest
      </h1>
      <hr style={{ color: "white" }} />
      <div>
        <h2
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            backgroundColor: "rgb(24 61 61 / 46%)",
          }}
        >
          Flowers
        </h2>
        <hr style={{ color: "white" }} />
      </div>
      <div className="img-container">
        {natureImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
      <div>
        <hr style={{ color: "white" }} />
        <h2
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            backgroundColor: "rgb(24 61 61 / 46%)",
          }}
        >
          Forest
        </h2>
        <hr style={{ color: "white" }} />
      </div>
      <div className="img-container">
        {forestImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default NatureForestPortfolio;
