import React from "react";
import "./portfolio-pages.css";
import {
  birdsImages,
  macroInsectImages,
} from "../imageUrlLists/wildlifeImagesList";

function WildLifePortfolio() {
  return (
    <div className="container-custom">
      <hr style={{ color: "white", marginTop: 0 }} />
      <h1
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        Wild Life
      </h1>
      <hr style={{ color: "white" }} />
      <div>
        <div>
          <h2
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              backgroundColor: "rgb(24 61 61 / 46%)",
            }}
          >
            Macro photos
          </h2>
          <hr style={{ color: "white" }} />
        </div>
        <div className="img-container">
          {macroInsectImages.map((image) => {
            return (
              <img
                src={image.src}
                alt=""
                style={{
                  width: image.width ?? "",
                  height: image.height ?? "",
                }}
              />
            );
          })}
        </div>
      </div>

      <div>
        <hr style={{ color: "white" }} />
        <h1
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            backgroundColor: "rgb(24 61 61 / 46%)",
          }}
        >
          Birds
        </h1>
        <hr style={{ color: "white" }} />
      </div>
      <div className="img-container">
        {birdsImages.map((image) => {
          return <img src={image.src} alt="" />;
        })}
      </div>
    </div>
  );
}

export default WildLifePortfolio;
