import streetImage1 from "../assets/street_photos/Augsburg Stadt 2-min.jpg";
import streetImage2 from "../assets/street_photos/Augsburg Stadt Fahrrad 2-min.jpg";
import streetImage3 from "../assets/street_photos/Street Ausgburg-27-min.jpg";
import streetImage4 from "../assets/street_photos/Schaufenster Reflection-min.jpg";
import streetImage5 from "../assets/street_photos/Street Stadt Wald Augsburg-17-mi.jpg";
import streetImage6 from "../assets/street_photos/Street Stadt Wald Augsburg-20-mi.jpg";
import streetImage7 from "../assets/street_photos/Street Stadt Wald Augsburg-21-mi.jpg";
import streetImage8 from "../assets/street_photos/Street Stadt Wald Augsburg-26-mi.jpg";
import streetImage9 from "../assets/street_photos/Street Stadt Wald Augsburg-63-mi.jpg";
import streetImage10 from "../assets/street_photos/Street Stadt Wald Augsburg-69-mi.jpg";
import streetImage13 from "../assets/street_photos/Street Stadt Wald Augsburg-73-mi.jpg";
import streetImage14 from "../assets/street_photos/Streetphoto weniger scharf-34-mi.jpg";
import streetImage15 from "../assets/street_photos/Streetphoto-30-min.jpg";
import streetImage17 from "../assets/street_photos/Streetphoto-31-min.jpg";
import streetImage18 from "../assets/street_photos/Street Ausgburg-13 (1).jpg";
import streetImage19 from "../assets/street_photos/Augsburg Stadt 1 (1).jpg";
import streetImage20 from "../assets/street_photos/Augsburg Stadt Statue 2 (1).jpg";
import streetImage33 from "../assets/street_photos/StreetPhoto1.jpg";
import streetImage22 from "../assets/street_photos/Street photo Augsburg-1.jpg";
import streetImage23 from "../assets/street_photos/Street photo Augsburg-4.jpg";
import streetImage24 from "../assets/street_photos/Street photo Augsburg-6.jpg";
import streetImage25 from "../assets/street_photos/Street photo Augsburg-8.jpg";
import streetImage26 from "../assets/street_photos/Street photo Augsburg-10.jpg";
import streetImage27 from "../assets/street_photos/Street photo Augsburg-12.jpg";
import streetImage28 from "../assets/street_photos/Street photo Augsburg-14.jpg";
import streetImage29 from "../assets/street_photos/Street photo Augsburg-15.jpg";
import streetImage30 from "../assets/street_photos/Street photo Augsburg-16.jpg";
import streetImage31 from "../assets/street_photos/Street photo Augsburg-18.jpg";
import streetImage32 from "../assets/street_photos/Street photo Augsburg-19.jpg";
import nightStreet1 from "../assets/street_photos/plärrer/Plärrer-1.jpg";
import nightStreet2 from "../assets/street_photos/plärrer/Plärrer-12.jpg";
import nightStreet3 from "../assets/street_photos/plärrer/Plärrer-13.jpg";
import nightStreet4 from "../assets/street_photos/plärrer/Plärrer-14.jpg";
import nightStreet5 from "../assets/street_photos/plärrer/Plärrer-5.jpg";
import nightStreet6 from "../assets/street_photos/plärrer/Plärrer-6.jpg";

export const streetImages = [
  {
    src: streetImage1,
  },
  {
    src: streetImage2,
  },
  {
    src: streetImage3,
  },
  {
    src: streetImage4,
  },
  {
    src: streetImage5,
  },
  {
    src: streetImage6,
  },
  {
    src: streetImage7,
  },
  {
    src: streetImage8,
  },
  {
    src: streetImage9,
  },
  {
    src: streetImage10,
  },
  {
    src: streetImage13,
  },
  {
    src: streetImage14,
  },
  {
    src: streetImage15,
  },
  {
    src: streetImage17,
  },

  {
    src: streetImage18,
  },

  {
    src: streetImage19,
  },

  {
    src: streetImage20,
  },
  {
    src: streetImage22,
    width: "650px",
    height: "450px",
  },
  {
    src: streetImage23,
  },
  {
    src: streetImage24,
  },
  {
    src: streetImage25,
    width: "650px",
    height: "450px",
  },
  {
    src: streetImage26,
    width: "650px",
    height: "450px",
  },
  {
    src: streetImage27,
    width: "650px",
    height: "450px",
  },
  {
    src: streetImage28,
    width: "650px",
    height: "450px",
  },
  {
    src: streetImage29,
  },
  {
    src: streetImage30,
  },
  {
    src: streetImage31,
  },
  {
    src: streetImage32,
  },
];

export const nightStreetImages = [
  {
    src: streetImage33,
    width: "600px",
    height: "450px",
  },
  {
    src: nightStreet1,
  },

  {
    src: nightStreet2,
  },

  {
    src: nightStreet3,
  },

  {
    src: nightStreet4,
  },

  {
    src: nightStreet5,
    width: "710px",
    height: "450px",
  },

  {
    src: nightStreet6,
  },
];
