import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./carousel.css";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img
          src={require("../../assets/carousel_pictures/Schloss Neuschwanstein-16.jpg")}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "700px",
            filter: "brightness(60%)",
          }}
          alt=""
        />

        <Carousel.Caption style={{ marginTop: "10px" }}>
          <div
            style={{
              border: "2px solid white",
              backgroundColor: "white",
              color: "black",
              opacity: 0.8,
              padding: "15px",
              fontSize: "0.7rem",
              width: "fit-content",
              borderRadius: "6px",
              margin: "0 auto",
            }}
          >
            <h3>
              <strong>Welcome to my photography world</strong>
            </h3>
            <span className="text-carousel-big">
              where you can find landscape photos...
            </span>
            <br />
            <div style={{ paddingTop: "10px" }}>
              <span className="text-carousel-smaller">
                ~The mind is never more highly gratified than in contemplating a
                natural landscape~
              </span>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={require("../../assets/forest_and_nature_pictures/Westliche Wälder-3-min.jpg")}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "700px",
            filter: "brightness(60%)",
          }}
          alt=""
        />
        <Carousel.Caption>
          <div
            style={{
              border: "2px solid white",
              backgroundColor: "white",
              color: "black",
              opacity: 0.8,
              padding: "15px",
              fontSize: "0.7rem",
              width: "fit-content",
              borderRadius: "6px",
              margin: "0 auto",
            }}
          >
            <h3>
              <strong>Nature photography</strong>
            </h3>
            <span className="text-carousel-big">
              All around the nature and its animals, flowers and the smallest
              inhabitants.{" "}
            </span>
            <br />
            <div style={{ paddingTop: "10px" }}></div>
            <span className="text-carousel-smaller">
              ~Sometimes the smallest things in life are the most beautiful~
            </span>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={require("../../assets/carousel_pictures/Street photo Augsburg-12.jpg")}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "700px",
            filter: "brightness(60%)",
          }}
          alt=""
        />
        <Carousel.Caption style={{ marginTop: "10px" }}>
          <div
            style={{
              border: "2px solid white",
              backgroundColor: "white",
              color: "black",
              opacity: 0.8,
              padding: "15px",
              fontSize: "0.7rem",
              width: "fit-content",
              borderRadius: "6px",
              margin: "0 auto",
            }}
          >
            <h3>
              <strong>Street & Urban Photography</strong>
            </h3>
            <span className="text-carousel-big">
              See cities from another perspective
            </span>
            <br />
            <div style={{ paddingTop: "10px" }}>
              <span className="text-carousel-smaller">
                ~In the city, you never know what you'll find around the next
                corner~
              </span>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={require("../../assets/carousel_pictures/Wattenmeer-15-min.jpg")}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "700px",
            filter: "brightness(60%)",
          }}
          alt=""
        />
        <Carousel.Caption style={{ marginTop: "10px" }}>
          <div
            style={{
              border: "2px solid white",
              backgroundColor: "white",
              color: "black",
              opacity: 0.8,
              padding: "15px",
              fontSize: "0.7rem",
              width: "fit-content",
              borderRadius: "6px",
              margin: "0 auto",
            }}
          >
            <h3>
              <strong>Wildlife</strong>
            </h3>
            <span className="text-carousel-big">
              All around animals both the small and large ones.
            </span>
            <br />
            <div style={{ paddingTop: "10px" }}>
              <span className="text-carousel-smaller">
                ~Each species is a masterpiece, a creation assembled with
                extreme care and genius.~
              </span>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={require("../../assets/carousel_pictures/Nordic Walking Tour.jpg")}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "700px",
            filter: "brightness(50%)",
          }}
          alt=""
        />
        <Carousel.Caption style={{ marginTop: "10px" }}>
          <div
            style={{
              border: "2px solid white",
              backgroundColor: "white",
              color: "black",
              opacity: 0.8,
              padding: "15px",
              fontSize: "0.7rem",
              width: "fit-content",
              borderRadius: "6px",
              margin: "0 auto",
            }}
          >
            <h3>
              <strong>Forest Pictures</strong>
            </h3>
            <span className="text-carousel-big">
              find pictures from the beautiful vegetation and forests in
              different seasons
            </span>
            <br />
            <div style={{ paddingTop: "10px" }}>
              <span className="text-carousel-smaller">
                ~I enjoy an easy walk in the forest with my own thoughts, I can
                escape from the world for a bit~
              </span>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
