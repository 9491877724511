import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const pages = [
  { pageName: "Street & Urban", pageLink: "/street-portfolio" },
  { pageName: "Landscapes", pageLink: "/landscape-portfolio" },
  { pageName: "Flowers & Forest", pageLink: "/nature-forest-portfolio" },
  { pageName: "Wildlife", pageLink: "/wild-life-portfolio" },
  { pageName: "Travels", pageLink: "/travels-portfolio" },
  /* { pageName: "Highlights & Throwbacks", pageLink: "/highlights" }, */
];
const dropDownPages = [
  { pageName: "London", pageLink: "/travels-london-portfolio" },
  { pageName: "Neuschwanstein", pageLink: "/travels-neuschwanstein-portfolio" },
  { pageName: "Northsea", pageLink: "/travels-northsea-portfolio" },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorElNavbar, setAnchorElNavbar] = useState<null | HTMLElement>(
    null
  );

  const recordButtonPosition = (event: any) => {
    event.stopPropagation();
    setAnchorElNavbar(event.currentTarget);
    setMenuOpen(true);
  };

  let closeMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuOpen(false);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#183D3D" }}>
      <Container maxWidth="xl" sx={{ paddingLeft: "0px", marginLeft: "0px" }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={require("../assets/Logo Wasserzeichen Weiß Logo Links.png")}
              alt=""
              style={{ height: "100%", width: "300px", display: "inline-flex" }}
            />
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.pageName}
                  href={page.pageLink}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#93B1A6",
                    display: "block",
                    ":hover": { color: "white", bgcolor: "#5C8374" },
                  }}
                >
                  {page.pageName}
                </Button>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={require("../assets/Logo Wasserzeichen Weiß Logo Links.png")}
              alt=""
              style={{ height: "100%", width: "300px", display: "inline-flex" }}
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => {
              if (page.pageName === "Travels") {
                return (
                  <>
                    <Button
                      key={page.pageName}
                      onClick={recordButtonPosition}
                      sx={{
                        my: 2,
                        color: "#93B1A6",
                        display: "block",
                        ":hover": { color: "white", bgcolor: "#5C8374" },
                      }}
                    >
                      {page.pageName}
                    </Button>
                    <Menu
                      key={page.pageName}
                      disableScrollLock={true}
                      id="menu-appbar"
                      anchorEl={anchorElNavbar}
                      open={menuOpen}
                      sx={{
                        pt: 0,
                        pb: 0,
                      }}
                      onClose={closeMenu}
                    >
                      {dropDownPages.map((dropDownPage) => (
                        <Button
                          sx={{
                            mt: 0,
                            mb: 0,
                            color: "#93B1A6",
                            display: "block",
                            ":hover": { color: "white", bgcolor: "#5C8374" },
                          }}
                          onClick={closeMenu}
                          href={dropDownPage.pageLink}
                        >
                          {dropDownPage.pageName}
                        </Button>
                      ))}
                    </Menu>
                  </>
                );
              } else {
                return (
                  <Button
                    key={page.pageName}
                    href={page.pageLink}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "#93B1A6",
                      display: "block",
                      ":hover": { color: "white", bgcolor: "#5C8374" },
                    }}
                  >
                    {page.pageName}
                  </Button>
                );
              }
            })}
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                  justifyContent: "flex-end",
                },
              }}
            ></Box>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            ></Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
